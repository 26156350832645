import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiHeader } from '../src/';
import { UiSpacing, Sizing } from '../../foundation/src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uiheader"
    }}>{`UiHeader`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/COMPONENT-NAME/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Header component, usually use to render logo and a few menu user actions`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/button`}</p>
    </blockquote>
    <h2 {...{
      "id": "header"
    }}>{`Header`}</h2>
    <p>{`The header component will render at the top but on scrolling the header will move with the scrolling`}</p>
    <Playground __position={1} __code={'<div style={{ position: \'relative\' }}>\n  <div\n    style={{\n      overflow: \'auto\',\n      maxHeight: \'300px\',\n      backgroundColor: \'lightGray\',\n    }}\n  >\n    <UiHeader>\n      <UiSpacing margin={{ left: Sizing.five }}>\n        <p>Header</p>\n      </UiSpacing>\n    </UiHeader>\n    <div style={{ color: \'black\' }}>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n    </div>\n  </div>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiHeader,
      UiSpacing,
      Sizing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        position: 'relative'
      }}>
    <div style={{
          overflow: 'auto',
          maxHeight: '300px',
          backgroundColor: 'lightGray'
        }}>
      <UiHeader mdxType="UiHeader">
        <UiSpacing margin={{
              left: Sizing.five
            }} mdxType="UiSpacing">
          <p>Header</p>
        </UiSpacing>
      </UiHeader>
      <div style={{
            color: 'black'
          }}>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
      </div>
    </div>
  </div>
    </Playground>
    <h2 {...{
      "id": "header-fixed"
    }}>{`Header fixed`}</h2>
    <p>{`The header component will kept positioned at the top of the content`}</p>
    <Playground __position={2} __code={'<div style={{ position: \'relative\' }}>\n  <div\n    style={{\n      overflow: \'auto\',\n      maxHeight: \'300px\',\n      backgroundColor: \'lightGray\',\n    }}\n  >\n    <UiHeader fixed>\n      <UiSpacing margin={{ left: Sizing.five }}>\n        <p>Header</p>\n      </UiSpacing>\n    </UiHeader>\n    <div style={{ color: \'black\' }}>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n      <p>Some content</p>\n    </div>\n  </div>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiHeader,
      UiSpacing,
      Sizing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        position: 'relative'
      }}>
    <div style={{
          overflow: 'auto',
          maxHeight: '300px',
          backgroundColor: 'lightGray'
        }}>
      <UiHeader fixed mdxType="UiHeader">
        <UiSpacing margin={{
              left: Sizing.five
            }} mdxType="UiSpacing">
          <p>Header</p>
        </UiSpacing>
      </UiHeader>
      <div style={{
            color: 'black'
          }}>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
        <p>Some content</p>
      </div>
    </div>
  </div>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiHeader} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      